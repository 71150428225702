::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: #ffffff;
}

::-webkit-scrollbar-thumb{
    background: #888;
    border-radius: 32px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.css-13cymwt-control,
.css-t3ipsp-control {
    background: white !important;
    /* border: none !important; */
    padding: 2px 8px !important;
    font-size: 16px;
}